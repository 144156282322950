<template>
  <b-card title="Configuracion - General">
    <b-card-text>
      <b-tabs content-class="mt-2" justified>
        <b-overlay
          :show="submitted"
          no-wrap
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        />
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col cols="9" md="9" lg="9" class="border-right">
                <b-form-group label="GENERAL" class="border p-1">
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="12" lg="12">
                      <b-form-group label="* NOMBRE" label-for="nombre">
                        <validation-provider
                          #default="{ errors }"
                          name="Nombre"
                          rules="required|min:3"
                        >
                          <b-form-input
                            id="nombre"
                            v-model="form.nombre"
                            size="sm"
                            lazy-formatter
                            :formatter="textFormatter"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small
                            v-for="error in errores.nombre"
                            :key="error"
                            class="text-danger"
                          >
                            {{ error }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="6" lg="12">
                      <b-form-group label="* ESLOGAN" label-for="eslogan">
                        <validation-provider
                          #default="{ errors }"
                          name="Descripcion"
                          rules="required|min:4"
                        >
                          <b-form-input
                            id="eslogan"
                            v-model="form.eslogan"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small
                            v-for="error in errores.eslogan"
                            :key="error"
                            class="text-danger"
                          >
                            {{ error }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0-5">
                    <b-col cols="12">
                      <b-form-group label="DIRECCION" label-for="direccion">
                        <validation-provider
                          #default="{ errors }"
                          name="Correo electrónico"
                        >
                          <b-form-input
                            id="direccion"
                            v-model="form.direccion"
                            :state="errors.length > 0 ? false : null"
                            size="sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-for="error in errores.direccion"
                            :key="error"
                            class="text-danger"
                          >
                            {{ error }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="6">
                      <b-form-group
                        label-cols="12"
                        label="* REGISTRO TRIBUTARIO DE LA EMPRESA"
                        label-for="ci_nit"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="NIT"
                          rules="required"
                        >
                          <b-form-input
                            id="ci_nit"
                            v-model="form.ci_nit"
                            :state="errors.length > 0 ? false : null"
                            size="sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-for="error in errores.ci_nit"
                            :key="error"
                            class="text-danger"
                          >
                            {{ error }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label-cols="12"
                        label="TELEFONO"
                        label-for="telefono"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Telefono"
                          rules=""
                        >
                          <b-form-input
                            id="telefono"
                            v-model="form.telefono"
                            :state="errors.length > 0 ? false : null"
                            size="sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-for="error in errores.telefono"
                            :key="error"
                            class="text-danger"
                          >
                            {{ error }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="* CORREO ELECTRONICO DE LA EMPRESA"
                        label-cols="12"
                        label-for="email"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Correo electrónico"
                          rules="required|email"
                        >
                          <b-form-input
                            id="email"
                            v-model="form.email"
                            :state="errors.length > 0 ? false : null"
                            size="sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-for="error in errores.email"
                            :key="error"
                            class="text-danger"
                          >
                            {{ error }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label-cols="12"
                        label="SITIO WEB"
                        label-for="sitio_web"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Sitio web"
                          rules=""
                        >
                          <b-form-input
                            id="sitio_web"
                            v-model="form.sitio_web"
                            :state="errors.length > 0 ? false : null"
                            size="sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-for="error in errores.sitio_web"
                            :key="error"
                            class="text-danger"
                          >
                            {{ error }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Departamento"
                        rules="required"
                      >
                        <b-form-group
                          label="* DEPARTAMENTO"
                          label-for="departamento"
                          label-cols="12"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            id="departamento"
                            v-model="departamento"
                            class="style-chooser select-size-sm"
                            label="nombre"
                            :clearable="false"
                            :options="departamentoItems"
                            :class="{ 'is-invalid': !!errors.length }"
                            @input="obtenerDepartamento"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Municipio"
                        rules="required"
                      >
                        <b-form-group
                          label="* MUNICIPIO"
                          label-for="municipio"
                          label-cols="12"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            id="municipio"
                            :key="form.municipio_id"
                            v-model="form.municipio_id"
                            class="style-chooser select-size-sm"
                            label="nombre"
                            :clearable="false"
                            :reduce="(op) => op.id"
                            :options="municipioItems"
                            :class="{ 'is-invalid': !!errors.length }"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="CORREO DE RECUPERACION"
                        label-for="email_recuperacion"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Correo electrónico"
                          rules="email"
                        >
                          <b-form-input
                            id="email_recuperacion"
                            v-model="form.email_recuperacion"
                            :state="errors.length > 0 ? false : null"
                            size="sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-for="error in errores.email_recuperacion"
                            :key="error"
                            class="text-danger"
                          >
                            {{ error }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Giro Comercial"
                        rules="required"
                      >
                        <b-form-group
                          label="* GIRO COMERCIAL"
                          label-for="giroComercial"
                          label-cols="12"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            disabled
                            id="giroComercial"
                            :key="form.giroComercial_id"
                            :value="form.giroComercial_id"
                            class="style-chooser select-size-sm"
                            label="nombre"
                            :clearable="false"
                            :reduce="(op) => op.id"
                            :options="giroComercialItems"
                            :class="{ 'is-invalid': !!errors.length }"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label="FORMATO DE CODIGO DE PRODUCTO"
                          label-for="formato_code_producto"
                          label-cols="12"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            id="formato_code_producto"
                            v-model="form.formato_code_producto"
                            class="style-chooser select-size-sm"
                            label="nombre"
                            :clearable="false"
                            :options="formatoCodeProductoItems"
                            :class="{ 'is-invalid': !!errors.length }"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label-cols="12"
                        label="PASSWORD DEFAULT"
                        label-for="password_default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Password Default"
                          rules=""
                        >
                          <b-form-input
                            id="password_default"
                            v-model="form.password_default"
                            :state="errors.length > 0 ? false : null"
                            size="sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-for="error in errores.password_default"
                            :key="error"
                            class="text-danger"
                          >
                            {{ error }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group label="FACTURACION" class="border p-1 mt-2">
                  <b-row class="mb-0-5">
                    <b-col cols="6" md="6">
                      <b-form-group label="Tamaño factura" class="border-right">
                        <div class="demo-inline-spacing">
                          <b-form-radio
                            v-model="form.tamanio"
                            name="some-radios"
                            value="1"
                            class="m-auto"
                          >
                            Rollo
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.tamanio"
                            name="some-radios"
                            value="2"
                            class="m-auto"
                          >
                            Media Pagina
                          </b-form-radio>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col cols="3" class="m-auto mt-2">
                      <label />
                      <b-form-checkbox v-model="form.facturacion">
                        FACTURACION
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="3" class="m-auto mt-2">
                      <label />
                      <b-form-checkbox v-model="form.print_ticket">
                        PRINT TICKET
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0-5">
                    <b-col cols="12">
                      <b-form-group label="API KEY" label-for="api_key">
                        <validation-provider
                          #default="{ errors }"
                          name="ApiKey"
                          rules=""
                        >
                          <b-form-textarea
                            id="api_key"
                            v-model="form.api_key"
                            :state="errors.length > 0 ? false : null"
                            size="sm"
                            rows="3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-for="error in errores.api_key"
                            :key="error"
                            class="text-danger"
                          >
                            {{ error }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col cols="3" md="3" lg="3">
                <b-col class="mb-0-5 mt-1">
                  <div class="contenedor-imagen-categoria-marca boxs1">
                    <b-img
                      v-if="vistaPreviaImagen"
                      :src="vistaPreviaImagen"
                      center
                      thumbnail
                      fluid
                      class="rounded"
                      alt="Sin imagen"
                    />
                    <b-img
                      v-else
                      v-bind="imgAtributos"
                      rounded
                      alt="Rounded image"
                      class="d-inline-block mr-1 mb-1 border"
                    />
                  </div>

                  <b-form-group class="mt-1" label="IMAGEN" label-for="imagen">
                    <validation-provider
                      #default="{ errors }"
                      name="imagen"
                      rules=""
                    >
                      <b-form-file
                        v-model="form.imagen"
                        accept="image/*"
                        placeholder="Elija una imagen"
                        drop-placeholder="Ningun imagen seleccionado"
                        @change="onFileChanged"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-for="error in errores.imagen"
                        :key="error"
                        class="text-danger"
                        >{{ error }}</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-col>
            </b-row>
            <!-- fila 1 -->
            <hr />
            <div class="text-right">
              <b-button
                v-if="$hascan('configuracion-editar')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                @click="validar()"
              >
                <span class="align-middle">ACTUALIZAR</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-tabs>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BTabs,
  BForm,
  BOverlay,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BImg,
  BFormRadio,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import formImagenMixin from "@/modules/administracion/mixins/form-imagen-mixin";
import vSelect from "vue-select";
import { required } from "@validations";
import { mapActions } from "vuex";
import ConfiguracionServices from "../services/index";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BTabs,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BOverlay,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    BFormFile,
    BImg,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  mixins: [formImagenMixin],
  data() {
    return {
      submitted: false,
      loading: false,
      errores: [],
      vistaPreviaImagen: "",
      departamentoItems: [],
      municipioItems: [],
      departamento: null,
      giroComercialItems: [],
      formatoCodeProductoItems: [],
      form: {
        id: null,
        nombre: null,
        eslogan: null,
        ci_nit: null,
        telefono: null,
        email: null,
        sitio_web: null,
        direccion: null,
        municipio_id: null,
        giroComercial_id: null,
        giro_comercial_id: null,
        email_recuperacion: null,
        formato_code_producto: null,
        password_default: null,
        facturacion: false,
        print_ticket: false,
        api_key: null,
        imagen: null,
        tamanio: 1,
      },
      required,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions({
      peticionCambiarSede: "auth/peticionCambiarSede",
    }),
    generarFormData() {
      const formData = new FormData();
      formData.append("nombre", this.form.nombre);
      formData.append("eslogan", this.form.eslogan);
      formData.append("ci_nit", this.form.ci_nit);
      formData.append("telefono", this.form.telefono);
      formData.append("email", this.form.email);
      formData.append("sitio_web", this.form.sitio_web);
      formData.append("direccion", this.form.direccion);
      formData.append("municipio_id", this.form.municipio_id);
      formData.append("email_recuperacion", this.form.email_recuperacion);
      formData.append("giro_comercial_id", this.form.giroComercial_id);
      formData.append("facturacion", this.form.facturacion);
      formData.append("print_ticket", this.form.print_ticket);
      formData.append(
        "formato_code_producto",
        this.form.formato_code_producto.nombre
      );
      formData.append("password_default", this.form.password_default);
      formData.append("api_key", this.form.api_key);
      formData.append("tamanio_factura", this.form.tamanio);

      if (this.form.imagen) formData.append("file", this.form.imagen);
      return formData;
    },
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            this.actualizar();
          }
        }
      });
    },

    async actualizar() {
      const formData = this.generarFormData();
      try {
        await ConfiguracionServices.update(this.form.id, formData);
        this.submitted = false;
        this.getData();
        this.errores = [];
        this.form.imagen = null;
        this.peticionCambiarSede({
          sede_id: this.sede_id,
        })
          .then(() => {
            this.$bvToast.toast("Se actualizo correctamente.", {
              title: "OK!",
              variant: "success",
              solid: false,
            });
          })
          .catch((err) => {
            this.loading = false;
            this.$bvToast.toast(err, {
              title: "ERROR!",
              variant: "danger",
              solid: false,
            });
          });
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    textFormatter(value) {
      return value.toUpperCase();
    },

    async getData() {
      try {
        const departamentos = await ConfiguracionServices.getDepartamentos();
        const girosComerciales =
          await ConfiguracionServices.getGirosComerciales();
        this.departamentoItems = departamentos.data;
        this.giroComercialItems = girosComerciales.data;
        this.formatoCodeProductoItems = [
          { nombre: "numerico" },
          { nombre: "alfanumerico" },
        ];
        const config = await (await ConfiguracionServices.get()).data;

        this.form.id = config.id;
        this.form.nombre = config.nombre;
        this.form.eslogan = config.eslogan;
        this.form.ci_nit = config.ci_nit;
        this.form.telefono = config.telefono;
        this.form.email = config.email;
        this.form.sitio_web = config.sitio_web;
        this.form.direccion = config.direccion;
        this.vistaPreviaImagen = config.imagen;
        this.form.giro_comercial_id = config.giro_comercial_id;
        this.form.email_recuperacion = config.email_recuperacion;
        this.form.formato_code_producto = {
          nombre: config.formato_code_producto,
        };
        this.form.password_default = config.password_default;
        this.form.facturacion = config.facturacion;
        this.form.print_ticket = config.print_ticket;
        this.form.api_key = config.api_key;
        let result = this.departamentoItems.find((departamento) => {
          if (departamento.id == config.municipio.departamento_id)
            return {
              id: departamento.id,
              nombre: departamento.nombre,
            };
        });
        this.obtenerDepartamento(result);
        this.departamento = result;
        this.form.municipio_id = config.municipio_id;
        this.form.giroComercial_id = config.giro_comercial_id;
        this.form.facturacion = config.facturacion;
        this.form.print_ticket = config.print_ticket;
        this.form.tamanio = config.tamanio_factura;
      } catch (err) {
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    obtenerDepartamento(value) {
      if (value) {
        this.municipioItems = value.municipios;
        this.form.municipio_id = null;
      } else {
        this.municipioItems = [];
        this.form.municipio_id = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.vs__deselect {
  fill: rgb(255 255 255);
}
.contenedor-imagen-categoria-marca {
  height: 14rem;
  background: #fff;
}

.contenedor-imagen-categoria-marca img {
  width: 100%;
  height: 100%;
}
.boxs1 img {
  object-fit: contain;
}
</style>
